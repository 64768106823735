<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <!-- Abre Cabeçalho -->
        <BannerAbout id="header-privacidade" imgBack="politica" />
        <!-- Fecha Cabeçalho -->

        <!-- Abre secao das solucoes -->
        <section class="list list-new-destaques solucoes">
          <div class="container" style="max-width: 960px">
            <h2 class="title1" style="margin-bottom: 73px">
              <span> <b>Política</b> de Privacidade </span>
            </h2>
            <p>
              <b
                >O site <span class="notranslate">Shinier</span> na internet
                destina-se a um público geral e usuários de todas as idades.</b
              >
            </p>

            <p>
              A <span class="notranslate">Shinier</span> se obriga, nos termos
              da legislação em vigor, a garantir a privacidade dos usuários
              deste site. Para tanto, declara expressamente não dispor de
              quaisquer mecanismos que identifiquem pessoalmente os usuários
              e/ou seus respectivos endereços eletrônicos, exceto quando por
              estes voluntariamente prestados. Esta Política de Privacidade se
              aplica à obtenção, uso e revelação de Informações Pessoais em
              nosso website.
            </p>

            <p>
              A <span class="notranslate">Shinier</span> obriga-se expressamente
              a não coletar e não repassar a terceiros quaisquer informações que
              impliquem na identificação pessoal do usuário, exceto quando por
              estes expressamente autorizados. “Informações Pessoais” são
              informações fornecidas que identificam você pessoalmente,
              incluindo, por exemplo, seu nome completo, endereço, telefone e
              endereço de e-mail.
            </p>

            <p>
              Nós obtemos as suas informações pessoais quando você nos envia uma
              informação ou um pedido (por meio deste website ou de alguma outra
              forma). Nós usamos essas informações para o envio dos e-mails que
              você pediu ou por razões administrativas (como para comunicar
              sobre mudanças feitas, por exemplo, nesta Política de
              Privacidade).
            </p>

            <p>
              Declara-se desde já que não utilizamos de tecnologia de mapa de
              calor, contabilizamos os números de visitação deste site e de suas
              páginas subjacentes, também fazemos da utilização de cookies
              quando autorizados e estes são armazenados no banco de dados do
              seu navegador, que pode ser renovado ao limpar os cookies deste
              site.
            </p>

            <p style="margin-top: 50px">
              <b>Mudanças na Política de Privacidade</b>
            </p>

            <p>
              A <span class="notranslate">Shinier</span> pode alterar esta
              política de privacidade de tempos em tempos. Caso haja alguma
              mudança substancial na forma de utilização das suas informações,
              iremos notificá-lo com um anúncio em destaque em nosso site.
            </p>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import BannerAbout from "../../components/Banner/BannerAbout";
import scrollToTop from "../../mixins/scrollToTop";
export default {
  name: "Politica",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Política de Uso de Dados",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "description",
        content:
          "O site Shinier na internet destina-se a um público geral e usuários de todas as idades."
      }
    ]
  },
  mixins: [scrollToTop],
  components: {
    BannerAbout
  }
};
</script>
