const choosePlan = {
  methods: {
    choosePlan(id) {
      let message = "";
      if (id === "choose_mvp") {
        message +=
          "Olá Marcius, estou interessado em desenvolver um Mínimo Produto Viável";
        this.sendPlan(message);
      }
      if (id === "choose_beta") {
        message +=
          "Olá Marcius, estou interessado em desenvolver a Versão Beta";
        this.sendPlan(message);
      }

      if (id === "choose_versao1") {
        message += "Olá Marcius, estou interessado em desenvolver a Versão 1";
        this.sendPlan(message);
      }

      if (id === "marketplace") {
        message += "Olá Marcius, estou interessado em ter meu marketplace";
        this.sendPlan(message);
      }

      if (id === "aceleracao") {
        message += "Olá Marcius, desejo uma aceleração";
        this.sendPlan(message);
      }
    },
    sendPlan(message) {
      window.open(
        `https://api.whatsapp.com/send?phone=5516981354817&text=${message}`
      );
    }
  }
};

export default choosePlan;
