<template>
  <div id="page-banner2" :class="'page-head head-segmentos ' + imgBack">
    <div :id="shinier ? 'header-sobre' : null" class="container">
      <nav class="breadcrumb" v-if="!shinier || !pol || !art || !fab">
        <ul class="breadcrumb" itemscope v-if="!shinier">
          <li itemprop="itemListElement" itemscope v-if="!pol || !shinier">
            <i :class="icon1"></i>
            <span itemprop="name" class="breadcrumb-span">{{ itemProps }}</span>
          </li>
        </ul>
      </nav>
      <h1 id="title" class="title-h1" style="text-align: center">
        <i :class="icon"></i> {{ title }}
      </h1>

      <!--aqui apenas se aplica a home e sobre-->

      <div class="container-pageheader">
        <div class="logo-position" v-if="shinier || pol">
          <!-- <img
            id="logo2"
            class="banner-logo"
            src="../../../assets/icons/shinier.svg"
          />
          <img
            id="logo-text2"
            class="banner-logo-text"
            src="../../../assets/icons/shinier_text.webp"
          /> -->
          <img
          style="width: 50%;
    margin-left: 25%;
    margin-top: -8%;"
            src="../../../assets/icons/logo-banner.png"
          />
        </div>
      </div>

      <div v-if="shinier || pol" class="banner-logo-sub">
        <hr />
        <span>
          Soluções empresariais
          <br />tecnológicas
        </span>
        <hr />
      </div>

      <div
        class="container-pageheader"
        v-if="art || fab"
        :style="!shinier || !fab ? 'margin-top: 5% !important;' : ''"
      >
        <h1 id="title" class="title-h1" style="text-align: center">
          <i :class="icon3"></i>
        </h1>
        <div
          :class="
            art
              ? 'banner-logo-sub banner-logosub-art'
              : 'banner-logo-sub banner-logosub-fab'
          "
        >
          <hr />
          <span style="font-size: 14px !important">
            Soluções empresariais
            <br />e tecnologias
          </span>
          <hr />
          <h1 class="slogan-2" v-if="art">
            Publicações periódicas para embasar as melhores decisões do seu
            negócio
          </h1>
          <h1 class="slogan-3" v-if="fab">
            DESENVOLVA SEU APP, SISTEMA WEB, OU SOFTWARE COM A SHINIER
          </h1>
        </div>
      </div>

      <h3 v-if="pol" class="txt-politica">
        © Copyright {{ new Date().getFullYear() }}
        <span class="notranslate"> Shinier. </span>| Todos os direitos
        reservados.<br />
      </h3>
      <div class="ico" v-if="shinier">
        <i :class="icons2"></i>
      </div>
      <!--fim v-if home e sobre-->
    </div>
  </div>
</template>
<script>
export default {
  props: ["imgBack", "itemProps", "icon1", "icon", "title", "icons2", "icon3"],
  data() {
    return {
      pol: "",
      shinier: "",
      art: "",
      fab: ""
    };
  },
  created() {
    this.pol = this.$route.name === "Politica";
    this.shinier = this.$route.name === "Shinier";
    this.art = this.$route.name === "Artigos";
    this.fab = this.$route.name === "Fabrica";
  }
};
</script>
<style scoped src="./index.css"></style>
