<template>
  <div class="is-rounded">
    <a @click="$router.push({ name: route })">
      <div class="all-soluction container duvidas">
        <div :class="'card-img-duvidas ' + imgCard"></div>
        <h3 class="p-card-faq title-faq">
          <span>{{ title }}</span>
        </h3>
        <p class="p-card-faq-desc">
          {{ desc }}
          <a class="a-title-faq"> &nbsp; Leia mais...</a><br />
        </p>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ["title", "desc", "route", "imgCard"]
};
</script>
<style scoped>
@import url("./index.css");
</style>
