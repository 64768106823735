<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <!-- Abre Cabeçalho -->
        <BannerAbout
          imgBack="fabrica"
          icon3="icon-shinier-color-48 icon-fabrica-text"
        />
        <!-- Fecha Cabeçalho -->

        <section
          class="list list-new-destaques solucoes top-mobile"
          style="background-color: white; margin-bottom: 0"
        >
          <div class="container">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                Solução em
                <b>Destaque</b>
              </span>
            </h2>

            <p style="text-align: center; color: #6e6e6e">
              Nossa especialidade é o desenvolvimento de plataformas, conectando
              os serviços ou produtos da sua empresa aos seus clientes e
              fornecedores. Temos o know-how desde a fase do MVP até o go-to
              market, estaremos te auxiliando em todo esse processo, não só
              desenvolvendo a tecnologia, mas conectando a parceiros,
              investidores e colaboradores. Porque sabemos que o seu crescimento
              também é o nosso sucesso.
            </p>
          </div>

          <!-- <div class="container">
            

            <div class="columns" style="margin: 5% 0 5% 0">
              <div class="column is-mobile is-vcentered bg-phone">
                <div
                  id="choose_mvp"
                  @click="choosePlan('choose_mvp')"
                  alt=""
                  class="mvp-container"
                >
                  <div class="row"><h2>Mínimo Produto Viável</h2></div>
                  <div class="row">
                    <p>
                      Iremos customizar as funcionalidade na tecnolgoia
                      acessível para seu orçamento, afim de você já poder
                      começar a faturar
                    </p>
                  </div>

                  <div class="row m-5">
                    <span class="txt-span">
                      Ticket médio 12x
                      <b style="color: rgb(87, 130, 173)">R$ 1200,00</b>
                    </span>
                  </div>
                  <div class="row m-5">
                    <span class="txt-span"
                      >Tempo de entrega em
                      <b style="color: rgb(87, 130, 173)">~3 meses</b></span
                    >
                  </div>
                  <div class="row m-5">
                    <span class="txt-span"
                      >Contrato mínimo
                      <b style="color: rgb(87, 130, 173)">1 ano</b></span
                    >
                  </div>
                  <progress value="10" max="100"></progress>
                </div>
              </div>
              <div class="column is-mobile is-vcentered bg-phone bg-beta">
                <div
                  id="choose_beta"
                  @click="choosePlan('choose_beta')"
                  class="mvp-container"
                  alt=""
                >
                  <div class="row"><h2>Versão Beta</h2></div>
                  <div class="row">
                    <p>
                      Você já possuí vendas, esta usando um conjunto de serviços
                      para realizar seu serviço ou produto, e deseja algo para
                      escalar
                    </p>
                  </div>
                  <div class="row m-5">
                    <span class="txt-span"
                      >Ticket médio 12x
                      <b style="color: rgb(87, 130, 173)">R$ 3200,00</b></span
                    >
                  </div>
                  <div class="row m-5">
                    <span class="txt-span"
                      >Tempo de entrega em
                      <b style="color: rgb(87, 130, 173)">~6 meses</b></span
                    >
                  </div>
                  <div class="row m-5">
                    <span class="txt-span"
                      >Contrato mínimo
                      <b style="color: rgb(87, 130, 173)">1 ano</b></span
                    >
                  </div>

                  <span role="button" class="btn-beta">Continuar</span>
                </div>
              </div>
              <div class="column is-mobile is-vcentered bg-phone">
                <div
                  id="choose_versao1"
                  @click="choosePlan('choose_versao1')"
                  class="mvp-container"
                  alt=""
                >
                  <div class="row">
                    <h2>Versão 1</h2>
                    <p style="font-size: x-small">
                      Você já possuí uma solução e precisa de automaitizar seus
                      processo ou integrar soluções distintas. Conte conosco
                      para acelerar seu negócio.
                    </p>
                  </div>

                  <div class="row m-2">
                    <span class="txt-span"
                      >Ticket médio 12x
                      <b style="color: rgb(87, 130, 173)">R$ 7200,00</b></span
                    >
                  </div>
                  <div class="row m-2">
                    <span class="txt-span"
                      >Tempo de entrega em
                      <b style="color: rgb(87, 130, 173)">~9 meses</b></span
                    >
                  </div>
                  <div class="row m-2">
                    <span class="txt-span"
                      >Contrato mínimo
                      <b style="color: rgb(87, 130, 173)">1 ano</b></span
                    >
                  </div>
                  <div class="row m-8" style="margin-top: 3%">
                    <span
                      role="textbox"
                      contenteditable="false"
                      aria-placeholder="CPF"
                      class="span-versao1"
                      >CPF</span
                    >
                  </div>
                  <div class="row m-8">
                    <span
                      role="textbox"
                      contenteditable="false"
                      aria-placeholder="CPF"
                      class="span-versao1"
                      >Senha</span
                    >
                  </div>
                  <div class="row m-5 row-space">
                    <span class="esqueci">Esqueci a senha </span>
                  </div>

                  <div class="span-txt-leitor">
                    <span class="leitor">Usar leitor de digital: Ativado </span>
                    <b-switch value="true" type="is-warning"></b-switch>
                  </div>
                  <span role="button" class="btn-beta">Entrar</span>
                  <span
                    role="button"
                    class="btn-beta"
                    style="background-color: #fc6c4c"
                    >Primeiro Acesso? Clique Aqui</span
                  >
                </div>
              </div>
            </div>

            <p
              style="
                color: #5782ad;
                font-size: 22px;
                font-weight: 700;
                text-align: center;
                padding-bottom: 4%;
              "
            >
              CLIQUE EM UM DOS SMARTPONHES ACIMA PARA SABER MAIS, SOBRE COMO
              PODEMOS ACELERAR UMA SOLUÇÃO SOB MEDIDA PARA VOCÊ!!!
            </p>
          </div> -->
        </section>

        <section
          class="list list-new-destaques solucoes"
          style="margin-bottom: 0"
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">O QUE É UMA SOFTWARE HOUSE?</span>
            </h2>
            <div class="container">
              <p class="text-center">
                As fábricas de software (também comumente chamadas de
                <i><b>software houses</b></i
                >) desempenham um papel crucial na indústria tecnológica,
                fornecendo serviços de desenvolvimento, manutenção e otimização
                de sistemas e aplicações. Contudo, clientes em potencial
                frequentemente têm uma série de dúvidas quando consideram
                trabalhar com uma fábrica de software.
              </p>
            </div>
          </div>
        </section>

        <section
          class="list list-new-destaques solucoes top-mobile"
          style="background-color: white; margin-bottom: 0"
        >
          <div class="container">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                O diferencial da
                <b>SHINIER</b>
              </span>
            </h2>
            <p
              class="content is-normal"
              style="text-align: center; color: #6e6e6e"
            >
              Vamos imaginar uma situação na qual sua empresa possui uma demanda
              interna importante: a digitalização de um processo que atualmente
              é realizado manualmente. Agora, você está integrado à equipe
              encarregada de tornar essa ideia realidade, e a pergunta inicial
              que naturalmente surge é: "Devemos criar nossa própria equipe de
              desenvolvimento de software ou buscar um parceiro externo
              especializado em tecnologia?" Tendo em vista isso, vamos abordar
              as principais dúvidas e, em seguida, analisar como a Shinier
              Solutions se diferencia:
            </p>
            <h3 class="subtitle is-4">
              Qualidade e Rigor Técnico: Como a fábrica de software garante a
              qualidade do código e das soluções entregues?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> O valor de "Rigor
              Tecnológico" evidencia o compromisso da empresa com a excelência
              técnica. Isso sugere uma dedicação à adoção de melhores práticas,
              revisão de código e testes automatizados.
            </p>
            <h3 class="subtitle is-4">
              Adaptabilidade: A solução oferecida pode ser adaptada às
              necessidades específicas do meu negócio?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> A empresa oferece planos
              adaptáveis (MVP, Beta e v1), além de módulos pré-formatados. Esta
              modularidade permite personalização conforme as necessidades
              específicas do cliente.
            </p>
            <h3 class="subtitle is-4">
              Integrações: Como a fábrica de software lida com a integração de
              sistemas legados e novas plataformas?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> A menção a módulos como
              autenticação de usuários, pagamentos e notificações em tempo real
              sugere uma abordagem holística, considerando as integrações mais
              cruciais em um ambiente empresarial.
            </p>
            <h3 class="subtitle is-4">
              Impacto e Sustentabilidade: O software desenvolvido considerará
              aspectos sociais e ambientais?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> A abordagem baseada em
              cores (Blue, Green e Red) destaca o compromisso da empresa com a
              transformação multidimensional: social, ambiental e financeira.
            </p>
            <h3 class="subtitle is-4">
              Escala e Crescimento: A fábrica de software pode auxiliar minha
              empresa a escalar e crescer?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> A missão da Shinier
              Solutions é explicitamente levar as empresas "do MVP ao Escalar",
              indicando um foco em apoiar o crescimento e a escalabilidade dos
              negócios.
            </p>
            <h3 class="subtitle is-4">
              Experiência e Expertise: Quais são as competências centrais da
              fábrica de software?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> A Shinier Solutions
              possui especialistas em várias áreas, como aplicativos, web,
              desktop, IoT e API, posicionando-se como uma solução tecnológica
              integral.
            </p>
            <h3 class="subtitle is-4">
              Cultura e Diversidade: A fábrica de software valoriza a
              diversidade e tem uma cultura alinhada com os valores da minha
              empresa?
            </h3>
            <p
              class="content is-normal"
              style="text-align: justify; color: #6e6e6e"
            >
              <b>Diferencial da Shinier Solutions:</b> Os valores da empresa
              incluem "Diversidade" e "Cultura", refletindo um ambiente
              inclusivo e um compromisso com a excelência não apenas técnica,
              mas também humana.
            </p>
            <br />
            <p
              class="content is-normal"
              style="text-align: center; color: #6e6e6e"
            >
              Em resumo, a Shinier Solutions se destaca em várias áreas chave
              quando comparada a fábricas de software tradicionais,
              principalmente devido à sua abordagem holística, comprometimento
              com a sustentabilidade e impacto social, e uma ampla gama de
              competências técnicas.
            </p>
            <a
              role="button"
              id="marketplace"
              style="
                background-color: #e5eaea;
                border-bottom: #c2c2c2 0.5px solid;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              "
              @click="choosePlan('marketplace')"
              class="btn btn-orange-solid btn-white-solid"
            >
              QUERO DESENVOLVER MEU SOFTWARE
            </a>
          </div>
        </section>

        <!-- abre seção dúvidas -->
        <section
          class="list list-new-destaques solucoes"
          style="margin-bottom: 0"
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">PRINCIPAIS DÚVIDAS</span>
            </h2>
            <div class="container">
              <p class="text-center">
                Estamos dispostos a responder todas as suas dúvidas. Abaixo você
                irá encontrar as principais perguntas que são levantadas por
                nossos clientes no momento de BRIEFING. Solicite um orçamento,
                ou consultoria, ou tire suas dúvidas com um consultor clicando
                no botão abaixo.
              </p>
            </div>

            <button @click="send()" class="btn-news-geral btn-orange-solid">
              <span><em>FALAR COM CONSULTOR</em></span>
            </button>
          </div>
          <!-- abre seção dúvidas (cards) -->
          <div
            class="slick-destaques slick-initialized slick-slider"
            style="padding: 2%"
          >
            <div class="columns is-multiline">
              <div
                class="column column is-full-tablet is-full-desktop is-half-widescreen"
                v-for="card in getCards"
                :key="card.route"
              >
                <CardDuvida
                  :imgCard="card.imgCard"
                  :title="card.title"
                  :desc="card.desc"
                  :route="card.route"
                />
              </div>
            </div>
          </div>
          <!-- fecha seção dúvidas (cards) -->
        </section>
        <!-- fecha seção dúvidas -->

        <section></section>

        <!-- fecha seção dúvidas (cards) -->
      </main>
    </div>
  </div>
</template>
<script>
import BannerAbout from "../components/Banner/BannerAbout";
import scrollToTop from "../mixins/scrollToTop";
import CardDuvida from "@/components/Cards/CardDuvida";
import choosePlan from "../mixins/choosePlan";
export default {
  name: "Fabrica",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Principais dúvidas - Fábrica de software",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "description",
        content:
          "Na Shinier desenvolvemos software com agilidade, customizando nossos módulos de tecnologia para seu modelo de negócio."
      },

      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Fábrica de software | Shinier"
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Fábrica de software | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/shinier/principais-duvidas-fabrica-de-software/"
      },
      {
        property: "og:description",
        content:
          "Na Shinier desenvolvemos software com agilidade, customizando nossos módulos de tecnologia para seu modelo de negócio."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/fabrica-text.8780bb8e.svg"
      },
      { property: "og:image:width", content: "300" },
      { property: "og:image:height", content: "300" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/fabrica-text.8780bb8e.svg"
      },
      {
        name: "twitter:description",
        content:
          "Na Shinier desenvolvemos software com agilidade, customizando nossos módulos de tecnologia para seu modelo de negócio."
      },
      { property: "og:site_name", content: "Fábrica de software | Shinier" }
    ]
  },
  computed: {
    getCards() {
      return this.$store.getters.questions;
    }
  },
  methods: {
    send() {
      window.open(
        "https://api.whatsapp.com/send?phone=5516981354817&text=Ol%C3%A1%20Marcius%20meu%20nome%20%C3%A9"
      );
    }
  },
  mixins: [scrollToTop, choosePlan /* showQuestions */],
  components: {
    BannerAbout: BannerAbout,
    CardDuvida
  }
};
</script>
<style scoped>
@import url("./Aceleracao/index.css");
@import url("./PrincipaisDuvidas/duvidas.css");

.list-new-destaques.solucoes .title1.title-solucao:before {
  background: var(--cor-cinza-claro) !important;
  border: solid 1px var(--cor-cinza-claro);
  border-bottom: 0.5px solid rgb(194, 194, 194);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.shadow-span {
  border-bottom: #c2c2c2 0.5px solid;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.list-new-destaques.solucoes .title1.title-shadow-span:before {
  border-bottom: #c2c2c2 0.5px solid;
  border: solid 1px var(--cor-complementar);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.list-new-destaques.solucoes
  .title1.title-solucao.title-no-span-before::before {
  background: none !important;
  border: none;
}

.bg-phone {
  background: url(../assets/images/Group100.svg) center no-repeat;
  background-size: contain;
  height: 100vh;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
}

.bg-phone.bg-beta {
  background: url(../assets/images/beta.svg) center no-repeat;
  background-size: contain;
  height: 100vh;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
}

.mvp-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  position: relative;
  align-items: center;
  margin: auto;
  justify-content: center;
  transform: scale(0.88);
  padding: 5%;
  width: 95%;
  flex: 2 auto;
}

.row.m-5 {
  margin: 5%;
}

.row.m-8 {
  margin: 8%;
}

.row.m-2 {
  margin: 2%;
}

.span-txt-leitor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: auto;
  width: max-content;
  gap: 50px;
}

.mvp-container h2 {
  font-size: 25px;
  text-align: center;
  color: black;
  flex-basis: 0;
  flex-grow: 0.5;
}

.mvp-container p {
  text-align: -webkit-auto;
  color: #515151;
  font-size: 15px;
  padding: 0 15% 0 10%;
  margin: 5%;
  flex-basis: 0;
  flex-grow: 0.5;
}

.mvp-container span[class="txt-span"] {
  text-align: center;
  letter-spacing: normal;
  color: #515151;
  font-size: 16px;
  flex-basis: 0;
  flex-grow: 0.5;
}

.mvp-container progress {
  accent-color: rgb(252, 172, 20) !important;
  height: 30px;
}

progress[value]::-webkit-progress-bar {
  background-color: #c2c2c2;
}

.btn-beta {
  padding: 3%;
  background: #fcac14;
  border-radius: 9px;
  width: 85%;
  height: 45px;
  line-height: 2;
  margin: 1%;
  text-align: center;
  font-weight: 700;
  color: black !important;
  flex-basis: 0;
  flex-grow: 0.5;
}

div#choose_versao1 {
  top: calc(100% - 90vh);
  transform: scale(0.8);
}

div#choose_beta,
div#choose_mvp {
  top: calc(100% - 90vh);
  transform: scale(0.89);
}

.switch input[type="checkbox"]:checked + .check.is-warning {
  background: rgb(251, 170, 19) !important;
}

.switch input[type="checkbox"]:checked + .check {
  background: rgb(252, 172, 20) !important;
}

.switch input[type="checkbox"]:checked + .check:before {
  background: rgb(0, 0, 0) !important;
}

.switch input[type="checkbox"] + .check:before {
  background: rgb(0, 0, 0) !important;
}

.mvp-container span[class="span-versao1"] {
  width: 80%;
  border: 1.17203px solid #c4c4c4;
  border-radius: 9px;
  margin: 3%;
  padding: 5%;
  height: 45px;
  text-align: left !important;
  font-size: 12px !important;
  flex-basis: 0;
  flex-grow: 0.5;
  position: absolute;
  left: 6.5%;
}

.mvp-container span.leitor {
  align-self: flex-start;
  font-size: 12px;
}

.mvp-container span.esqueci {
  /*  align-self: flex-start; */
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
}

.row-space {
  padding: 5%;
  margin-bottom: 0 !important;
  place-self: flex-start;
}

@media screen and (min-width: 1500px) {
  .mvp-container {
    top: 22%;
    padding: 5%;
  }

  div#choose_versao1 {
    top: calc(100% - 90vh);
  }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .mvp-container {
    top: 10%;
    padding: 5%;
    transform: scale(0.88);
  }

  div#choose_versao1 {
    margin-top: -10%;
    padding: 10%;
  }

  .mvp-container h2 {
    font-size: 18px;
  }

  .mvp-container p {
    font-size: 15px;
  }

  .mvp-container span {
    font-size: 15px;
  }

  .mvp-container > span.leitor {
    text-align: left;
    align-self: flex-start;
    padding: 11px !important;
    margin-left: 6%;
  }

  .btn-beta {
    width: 95%;
    margin: 4%;
    height: 50px;
    line-height: 1;
  }

  .mvp-container span[class="span-versao1"] {
    width: 75%;
  }

  .row.m-8 {
    margin: 8% 12% 20% auto;
  }
}

@media screen and (max-width: 374px) {
  .mvp-container {
    top: 5%;
  }

  div#choose_versao1 {
    margin-top: -2%;
    transform: scale(0.89);
  }

  div#choose_beta,
  div#choose_mvp {
    transform: scale(0.89);
  }

  .btn-beta {
    width: -webkit-fill-available;
    height: 50px;
  }

  .mvp-container p {
    padding: 0;
  }

  .span-txt-leitor {
    gap: 50px;
  }
}

/* tablets com altura maior que 700px */
@media screen and (min-width: 768px) and (max-width: 870px) and (max-height: 1300px) {
  .btn-beta {
    line-height: 2;
    transform: scale(0.89) !important;
  }

  .mvp-container span[class="span-versao1"] {
    width: 75%;
    transform: scale(0.79) !important;
  }
}

/* ipad e similares */
@media screen and (min-width: 922px) and (max-width: 1024px) and (max-height: 1500px) {
  .btn-beta {
    line-height: 2;
    width: 100%;
    transform: scale(0.89) !important;
  }

  .mvp-container {
    top: auto !important;
  }

  .mvp-container span[class="span-versao1"] {
    width: 75%;
    transform: scale(0.79) !important;
  }
}

/* iphone4 e semelhantes */
@media screen and (width: 320px) and (max-height: 560px) {
  div#choose_versao1 {
    margin-top: -25%;
    transform: scale(0.75);
  }

  div#choose_beta,
  div#choose_mvp {
    transform: scale(0.79);
  }

  .span-txt-leitor {
    gap: 15px;
  }

  .row.m-8 {
    margin: 10%;
  }

  .btn-beta {
    padding: 1% 3%;
    line-height: 2.3;
  }
}
</style>
