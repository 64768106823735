<template>
  <div>
    <div class="main-container">
      <main role="main">
        <!-- Abre Cabeçalho -->
        <BannerAbout
          id="header-sobre"
          imgBack="sobre"
          icons2="icon-allicons-color-48 icon-allicons"
        />
        <!-- Fecha Cabeçalho -->

        <!-- Abre secao da missao -->
        <section
          class="list list-new-destaques solucoes"
          style="margin-bottom: 0px"
        >
          <div id="missao" class="container">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">
                Nossa
                <b>Missão</b>
              </span>
            </h2>
            <p style="margin-bottom: 10px">
              Ajudar empresas de todos os portes a reagir às transições da
              indústria para se manterem competitivas. Nossos anos de
              experiência nos ensinaram que o sucesso da sua empresa é a nossa
              prioridade.
            </p>
            <p style="margin-bottom: 10px">
              Nossa equipe de especialistas está pronta para ajudar sua empresa
              a desenvolver estratégias não apenas para sobreviver, mas para
              prosperar no futuro. Ligue para nós hoje e agende a sua primeira
              consultoria.
            </p>
          </div>
        </section>

        <!-- Abre secao das historia -->
        <section
          class="list list-new-destaques solucoes bg-white"
          style="margin-bottom: 0px"
        >
          <div id="inovacao" class="container">
            <h2 class="title1 title-solucao">
              <span class="shadow-span">
                História de
                <b>Inovação</b>
              </span>
            </h2>
            <p style="margin-bottom: 10px">
              A pouco menos de 5 anos começamos alguns projetos freelancers com
              um propósito de atender a demanda de alguns projetos pontuais que
              através de contatos nos chegavam individualmente com demandas de
              uma software house. Porém com um serviço de excelência e preços
              justos a demanda de trabalho e tecnologia, logo nossos clientes
              nos indicavam a outros e logo nos primeiros meses escalamos nosso
              primeiro time de funcionários, nossa prioridade era ter um
              diferencial dos demais competidores e para isso priorizamos manter
              um salário acima da média, assim manter os melhores em nossa
              equipe.
            </p>

            <p style="margin-bottom: 10px">
              Porém ainda não tínhamos o fôlego necessário para inovar, foi a
              partir do início do terceiro ano, que nos preparamos para ir além
              de uma software house, nosso desejo de ser uma empresa de
              tecnologia de excelência, fez com que buscássemos nas
              universidades e fora do país o que será primordial para entregar a
              vocês o diferencial competitivo e avançar sobre o mercado 4.0,
              através de IoT's, IA e Cloud e Escalabilidade. Também agregamos ao
              nosso time especialistas nos segmentos que atuamos para ter o
              respaldo científico das soluções propostas, e atacar os processos
              chaves de impacto na curva de produção, tendo o foco a realidade
              de cada empresa.
            </p>

            <p style="margin-bottom: 10px">
              Hoje somos uma empresa de consultoria tecnológica com um corpo de
              funcionários experientes e com formação técnica primordial para
              trabalhar e coordenar um time de desenvolvimento, implantação e
              suporte, possuímos soluções consolidadas e validadas em empresas
              de pequeno, médio e grande porte. Ao longo desse último ano também
              fechamos parcerias com empresas que já atuam sobre sistemas
              integrados consolidados no mercado, e possuímos um time dedicado a
              estar constantemente atualizando-os para que estes ainda possam
              oferecer uma maior e rápida aderência a você nosso futuro cliente.
            </p>
          </div>
        </section>

        <!-- Abre secao das Shinier -->
        <section class="list list-new-destaques solucoes">
          <div id="shinier" class="container">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">
                Por quê
                <b class="notranslate">SHINIER</b>?
              </span>
            </h2>
            <p style="margin-bottom: 10px">
              O conceito de brilho ao negócio vem de nossa metodologia única de
              avaliação, inspecionando os diferentes setores do seu negócio
              atribuindo a cada variável (processo e nível) uma cor de aderência
              com perguntas chaves, e métricas já consolidadas no mercado
              econômico, processos de produção e técnicas de cada setor, assim
              chegamos nos pontos onde seu negócio tem maior potencial de ganhar
              brilho, ou seja, escalabilidade, tendo um comparativo com seus
              principais competidores.
            </p>

            <p style="margin-bottom: 10px">
              É claro que estabelecer os parâmetros de avaliação de cada empresa
              não é uma tarefa simples, e que em muitos segmentos a experiência
              de anos do profissional quase que intuitiva não pode ser ignorada.
              Por isso sempre fazemos um estudo de campo na empresa acompanhando
              as atividades diárias, desde o chão de fábrica, campo de
              plantação, ou pátio do estoque, até a mais alta gerência. Há
              muitas referências de técnicas a serem implantadas nos mais
              diversos segmentos, porém aplicar uma técnica cara, como por
              exemplo, a irrigação por gotejamento em pasto para alimentação de
              gado de corte, torna-se um risco ao produtor rural. É para poder
              apoiar os especialistas que a Shinier traz o que é de mais novo no
              mercado de sensores e algoritmos a preços competitivos para que
              seu cliente final não sinta no bolso e ainda receba um produto de
              maior qualidade.
            </p>

            <p style="margin-bottom: 10px">
              Além de trabalhar em parcerias com outras empresas de
              desenvolvimento tecnológico, possuímos nossas principais soluções,
              que são como joias a serem entregues, pois passaram por um
              processo de lapidação em ambientes reais. A experiência dos
              coordenadores de cada projeto foi essencial para chegarmos a
              sistemas com alto nível de excelência, reduzir e prever os pontos
              de falhas do sistema ou processos, e possuir uma modularização de
              maneira a agilizar a implantação nos mais diversos mercados. Hoje
              chega à sua mão uma solução com o brilho de uma verdadeira joia a
              ser integrada ao desenvolvimento escalar do seu negócio, entre em
              contato conosco e agende uma consultoria.
            </p>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import BannerAbout from "../components/Banner/BannerAbout";
import scrollToTop from "../mixins/scrollToTop";

export default {
  name: "Shinier",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Sobre a Shinier - Nossa missão e historia",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Nossa missão é ajudar empresas de todos os portes a reagir às transições da indústria para se manterem competitivas. Nossos anos de experiência nos ensinaram que o sucesso da sua empresa é a nossa prioridade."
      }
    ]
  },
  mixins: [scrollToTop],
  components: {
    BannerAbout: BannerAbout
  }
};
</script>
<style scoped>
.list-new-destaques.solucoes .title1.title-solucao:before {
  background: var(--cor-cinza-claro) !important;
  border: solid 1px var(--cor-cinza-claro);
  border-bottom: 0.5px solid rgb(194, 194, 194);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.shadow-span {
  border-bottom: #c2c2c2 0.5px solid;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.list-new-destaques.solucoes .title1.title-shadow-span:before {
  border-bottom: #c2c2c2 0.5px solid;
  border: solid 1px var(--cor-complementar);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.list-new-destaques.solucoes
  .title1.title-solucao.title-no-span-before::before {
  background: none !important;
  border: none;
}
</style>
