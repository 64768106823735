import { render, staticRenderFns } from "./Fabrica.vue?vue&type=template&id=6033b3ee&scoped=true&"
import script from "./Fabrica.vue?vue&type=script&lang=js&"
export * from "./Fabrica.vue?vue&type=script&lang=js&"
import style0 from "./Fabrica.vue?vue&type=style&index=0&id=6033b3ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6033b3ee",
  null
  
)

export default component.exports